import React from "react";
import Loadable from "@loadable/component";
import Header from "../Header";

import IntroContent from "../../content/IntroContent.json";
import MiddleBlockContent from "../../content/MiddleBlockContent.json";
import AboutContent from "../../content/AboutContent.json";
import MissionContent from "../../content/MissionContent.json";
import ProductContent from "../../content/ProductContent.json";
// ------------------------------------------------------------------
// import FeatureContentZero from "../../content/FeatureContentZero.json";
// import FeatureContentOne from "../../content/FeatureContentOne.json";
// import FeatureContentTwo from "../../content/FeatureContentTwo.json";
// import FeatureContentThree from "../../content/FeatureContentThree.json";
// ------------------------------------------------------------------
// import KeyFeatures from "./KeyFeatures";

// FeatureContentZero

// import ContactContent from "../../content/ContactContent.json";
// import buttons from "./buttons";

// const ContactFrom = Loadable(() => import("../ContactForm"));
const ContentBlock = Loadable(() => import("../ContentBlock"));
const MiddleBlock = Loadable(() => import("../MiddleBlock"));
const Container = Loadable(() => import("../../common/Container"));
const ScrollToTop = Loadable(() => import("../../common/ScrollToTop"));
// const TestingContent = Loadable(() => import("../../common/TestingContent"));

const Home = () => {
  return (
    <>
      <Header />
      <Container>
        <ScrollToTop />
        <ContentBlock
          type="right"
          first="true"
          title={IntroContent.title}
          content={IntroContent.text}
          button={IntroContent.button}
          icon="Intross.svg"
          id="intro"
        />
        <buttons />
        <MiddleBlock
          title={MiddleBlockContent.title}
          content={MiddleBlockContent.text}
        />
        <ContentBlock
          type="left"
          title={AboutContent.title}
          content={AboutContent.text}
          section={AboutContent.section}
          icon="fastrespons.svg"
          id="about"
        />
        <ContentBlock
          type="right"
          title={MissionContent.title}
          content={MissionContent.text}
          icon="repl.svg"
          id="waving.svg"
        />
        {/* <KeyFeatures /> */}
        <ContentBlock
          type="left"
          title={ProductContent.title}
          content={ProductContent.text}
          icon="aboutsec.svg"
          id="product"
        />
        {/* <ContactFrom
        title={ContactContent.title}
        content={ContactContent.text}
        id="contact"
      /> */}
      </Container>
    </>
  );
};

export default Home;
