import React, { Fragment } from "react";
import { Row, Col } from "antd";
// import i18n from "i18next";
import { withTranslation } from "react-i18next";
import Loadable from "@loadable/component";
import Fade from "react-reveal/Fade";
import * as S from "./styles";
// import { Link } from "gatsby";
import "./Style.css";
// import { FacebookFilled } from "antd";

// const SvgIcon = Loadable(() => import("../../common/SvgIcon"));
const Container = Loadable(() => import("../../common/Container"));

const Footer = ({ t }) => {
  // const handleChange = (event) => {
  //   i18n.changeLanguage(event.target.value);
  // };

  // const SocialLink = ({ href, src }) => {
  //   return (
  //     <a
  //       href={href}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       key={src}
  //       aria-label={src}
  //     >
  //       <SvgIcon src={src} width="25px" height="25px" />
  //     </a>
  //   );
  // };

  return (
    <Fragment>
      <Fade bottom>
        <S.Footer>
          <Container>
            <Row type="flex" justify="space-between">
              <Col lg={10} md={10} sm={12} xs={24}>
                <S.Language>{t("Contact")}</S.Language>
                <S.Large>{t("Tell us everything")}</S.Large>
                <S.Para>
                  {t(
                    `Do you have any question regarding the project? Feel free to reach out.`
                  )}
                </S.Para>
                <S.Para>
                  {t(`Company number`)}
                  {`: 13810036`}
                </S.Para>
                <a href="mailto:example@gmail.com">
                  <S.Chat>{t(`Let's Chat`)}</S.Chat>
                </a>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24}>
                <S.Title>{t("Policy")}</S.Title>
                <S.Large to="/privacy">{t("Privacy Policy")}</S.Large>
                {/* <S.Large to="/tercon">{t("Terms & Conditions")}</S.Large> */}
                <S.Large to="/termcondition">{t("Terms & Conditions")}</S.Large>
                <S.Large to="/liagrement">
                  {t("End-User License Agreement")}
                </S.Large>
              </Col>
              {/* <Col lg={6} md={6} sm={12} xs={24}>
                <S.Empty />
                <S.Large to="/">{t("Support Center")}</S.Large>
                <S.Large to="/">{t("Customer Support")}</S.Large>
              </Col> */}

              <Col lg={6} md={6} sm={12} xs={24}>
                <S.Title>{t("Company")}</S.Title>
                <S.Large to="/">{t("About")}</S.Large>
                {/* <S.Large to="/">{t("Blog")}</S.Large> */}
                <S.Large to="/">{t("Press")}</S.Large>
                {/* <S.Large to="/Features.js">{t("Feature")}</S.Large> */}
                {/* <S.Large to="/">{t("Careers & Culture")}</S.Large> */}
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col lg={10} md={10} sm={12} xs={24}>
                <S.Empty />
                <S.Language>{t("ADDRESS")}</S.Language>
                <S.Para>317 Wigan Road</S.Para>
                <S.Para>Atherton</S.Para>
                <S.Para>Lancashire</S.Para>
                <S.Para>M46 0QA</S.Para>
                <S.Para>UK</S.Para>
                <S.Para>Owned by EVER INDUSTRIES LTD</S.Para>
              </Col>

              {/* <Col lg={8} md={8} sm={12} xs={24}>
                <S.Title>{t("Company")}</S.Title>
                <S.Large to="/">{t("About")}</S.Large>
                <S.Large to="/">{t("Blog")}</S.Large>
                <S.Large to="/">{t("Press")}</S.Large>
                <S.Large to="/">{t("Careers & Culture")}</S.Large>
              </Col> */}
              <Col lg={6} md={6} sm={12} xs={24}>
                <S.Select>
                  {/* <S.Label htmlFor="select-lang">{t("Language")}</S.Label>
                  <S.LangSelect
                    onChange={handleChange}
                    value={i18n.language}
                    id="select-lang"
                  >
                    <option value="en">English</option>
                    <option value="es">Español</option>
                  </S.LangSelect> */}
                </S.Select>
              </Col>
            </Row>
          </Container>
        </S.Footer>
        <S.Extra>
          <Container border="true">
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ paddingTop: "3rem" }}
            >
              {/* <S.NavLink to="/"> */}
              {/* <S.LogoContainer> */}
              {/* <SvgIcon
                    src="ev.svg"
                    aria-label="homepage"
                    width="201px"
                    height="100px"
                  /> */}
              {/* <h3>Ev-Love</h3> */}
              {/* </S.LogoContainer> */}
              {/* </S.NavLink> */}
              <S.FooterContainer>
                {/* <SocialLink
                  href="https://github.com/username"
                  src="github.svg"
                />
                <SocialLink
                  href="https://twitter.com/username"
                  src="twitter.svg"
                />
                <SocialLink
                  href="https://www.linkedin.com/in/username/"
                  src="linkedin.svg"
                />
                <SocialLink
                  href="https://github.com/username/"
                  src="instagram.svg"
                /> */}

                {/* <a href="#" class="fa fa-facebook"></a>
                <a href="#" class="fa fa-twitter"></a>
                <a href="#" class="fa fa-google"></a>
                <a href="#" class="fa fa-linkedin"></a>
                <a href="#" class="fa fa-youtube"></a>
                <a href="#" class="fa fa-instagram"></a> */}
              </S.FooterContainer>
            </Row>
          </Container>
        </S.Extra>
      </Fade>
    </Fragment>
  );
};

export default withTranslation()(Footer);
