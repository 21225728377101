import React from "react";
import { Router } from "@reach/router";
// import Loadable from "@loadable/component";

import Footer from "../components/Footer";
// import SecHeader from "../components/SecHeader";
// import Header from "../components/Header/index";

import GlobalStyles from "../globalStyles";
// import Features from "../components/Features";
// import HomePage from "../pages/HomePage";

// import Features from "../pages/Features";
// import Blog from "../pages/Blog";
// import Features from "../pages/Features";
// import Blog from "../pages/Blog";
// import SecHeader from "../components/SecHeader";
// import Features from "../pages/Features";
import HomePage from "../pages/HomePage";
// import Admin from ""
// import Home from "../components/Home";
// const Home = Loadable(() => import(`../components/Home`));
// const Features = Loadable(() => import(`../components/Features`));

const MainRouter = () => {
  return (
    <>
      <GlobalStyles />
      {/* <Header /> */}
      {/* <SecHeader /> */}
      <Router>
        <HomePage path="/" />
      </Router>
      <Footer />
    </>
  );
};

export default MainRouter;
